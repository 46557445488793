<template>
    <div class="auth-wrapper auth-v2">
        <b-row class="auth-inner m-0">

            <!-- Brand logo-->
            <b-link class="brand-logo">
                <h2 class="brand-text text-primary ml-1">
                    Ehsan M
                </h2>
            </b-link>
            <!-- /Brand logo-->

            <!-- Login-->
            <b-col
                class="d-flex align-items-center auth-bg px-2 p-lg-5 mx-auto"
                lg="4"
            >
                <b-col
                    class="px-xl-2 mx-auto"
                    lg="12"
                    md="6"
                    sm="8"
                >
                    <b-card-title
                        class="font-weight-bold mb-1"
                        title-tag="h2"
                    >
                        Welcome to ehsan site! 👋
                    </b-card-title>
                    <b-card-text class="mb-2">
                        Please sign-in to your account and start the adventure
                    </b-card-text>

                    <!-- form -->
                    <validation-observer ref="loginValidation">
                        <b-form
                            class="auth-login-form mt-2"
                            @submit.prevent
                        >
                            <!-- email -->
                            <b-form-group
                                label="Email"
                                label-for="login-email"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="Email"
                                    rules="required|email"
                                >
                                    <b-form-input
                                        id="login-email"
                                        v-model="userEmail"
                                        :state="errors.length > 0 ? false:null"
                                        name="login-email"
                                        placeholder="john@example.com"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>

                            <!-- forgot password -->
                            <b-form-group>
                                <div class="d-flex justify-content-between">
                                    <label for="login-password">Password</label>
                                    <b-link :to="{name:'auth-forgot-password-v2'}">
                                        <small>Forgot Password?</small>
                                    </b-link>
                                </div>
                                <validation-provider
                                    #default="{ errors }"
                                    name="Password"
                                    rules="required"
                                >
                                    <b-input-group
                                        :class="errors.length > 0 ? 'is-invalid':null"
                                        class="input-group-merge"
                                    >
                                        <b-form-input
                                            id="login-password"
                                            v-model="password"
                                            :state="errors.length > 0 ? false:null"
                                            :type="passwordFieldType"
                                            class="form-control-merge"
                                            name="login-password"
                                            placeholder="············"
                                        />
                                        <b-input-group-append is-text>
                                            <feather-icon
                                                :icon="passwordToggleIcon"
                                                class="cursor-pointer"
                                                @click="togglePasswordVisibility"
                                            />
                                        </b-input-group-append>
                                    </b-input-group>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                            <!-- submit buttons -->
                            <b-button
                                block
                                type="submit"
                                variant="primary"
                                @click="validationForm"
                            >
                                Sign in
                            </b-button>
                            <h2 v-show="loginStatus === 'loading'">loading</h2>
                        </b-form>
                    </validation-observer>
                </b-col>

            </b-col>

            <!-- /Login-->
        </b-row>
    </div>
</template>

<script>
/* eslint-disable global-require */
// import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
    BButton,
    BCardText,
    BCardTitle,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BLink,
    BRow,
} from 'bootstrap-vue'
import { email, required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
    components: {
        BRow,
        BCol,
        BLink,
        BFormGroup,
        BFormInput,
        BInputGroupAppend,
        BInputGroup,
        BCardText,
        BCardTitle,
        BForm,
        BButton,
    },
    mixins: [togglePasswordVisibility],
    data() {
        return {
            password: '',
            userEmail: '',
            required,
            email,
        }
    },
    computed: {
        ...mapGetters({
           loginStatus: 'auth/status'
        }),
        passwordToggleIcon() {
            return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
        },
    },
    methods: {
        ...mapActions({
           login: 'auth/login'
        }),
        validationForm() {
            this.$refs.loginValidation.validate().then(success => {
                if (success) {
                    this.login({
                        email: this.userEmail,
                        password: this.password,
                    }).then(() => {
                        window.location = '/'
                        // this.$router.push({'name': "dashboard"})
                    }).catch(error => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: data.message,
                                icon: 'EditIcon',
                                variant: 'danger',
                            },
                        })
                        this.$refs.loginValidation.setErrors(error.response.data.errors)
                    })
                }
            })
        }
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
